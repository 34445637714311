import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { env, isLocal } from 'app-config';
import i18n from 'app-utils/i18n';
import { MinimalLayout, SidebarLayout, SpaceyLayout } from './Layouts';

/**
 * Page layout
 *
 * Decides on the page's layout.
 */
const PageLayout = ({
  title,
  variant,
  showNotificationCenter,
  showBottomNavigation,
  ...rest
}) => {
  switch (variant) {
    case 'minimal':
      return <MinimalLayout showLogo {...rest} />;
    case 'sidebar':
      return (
        <SidebarLayout
          title={title}
          showNotificationCenter={showNotificationCenter}
          showBottomNavigation={showBottomNavigation}
          {...rest}
        />
      );
    case 'spacey':
      return (
        <SpaceyLayout
          title={title}
          showNotificationCenter={showNotificationCenter}
          {...rest}
        />
      );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <React.Fragment {...rest} />;
  }
};

PageLayout.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([null, 'minimal', 'sidebar', 'spacey']),
  showNotificationCenter: PropTypes.bool,
  showBottomNavigation: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
};

PageLayout.defaultProps = {
  title: null,
  showNotificationCenter: false,
  showBottomNavigation: false,
  variant: null,
  children: null,
};

/**
 * GetPageTitle
 *
 * Constructs the page's `<title />` tag. Suffixes title with `(Environment)`
 * when not in production.
 *
 * @param title
 * @return {string}
 */
const getPageTitle = (title) =>
  [
    title,
    title && '-',
    i18n.t('glossary:maklerportal'),
    isLocal && `(${env.charAt(0).toUpperCase() + env.slice(1)})`,
  ]
    .filter(Boolean)
    .join(' ');

/**
 * Page
 *
 * The outermost component for every page. Responsible for handling global
 * functionality. Optionally handles layout for convenience, but can be opted
 * out of for more complex cases.
 */
const Page = ({
  title,
  layout,
  showNotificationCenter,
  showBottomNavigation,
  children,
  mainProps,
}) => (
  <>
    <Head>
      <title>{getPageTitle(title)}</title>
    </Head>

    <PageLayout
      variant={layout}
      title={title}
      showNotificationCenter={showNotificationCenter}
      showBottomNavigation={showBottomNavigation}
      {...mainProps}
    >
      {children}
    </PageLayout>
  </>
);

Page.propTypes = {
  title: PropTypes.string,
  showNotificationCenter: PropTypes.bool,
  showBottomNavigation: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
    PropTypes.array,
  ]),
  layout: PropTypes.oneOf([null, 'minimal', 'sidebar', 'spacey']),
  mainProps: PropTypes.object,
};

Page.defaultProps = {
  title: null,
  showNotificationCenter: false,
  showBottomNavigation: true,
  children: null,
  layout: null,
  mainProps: {},
};

export default Page;
