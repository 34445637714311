/**
 * A generic API error
 *
 * Can be used to throw errors and attach a response and request to it.
 */
class ApiError extends Error {
  constructor(error, response, request) {
    super(error);

    this.name = 'ApiError';

    this.response = response;
    this.request = request;
  }
}

export default ApiError;
