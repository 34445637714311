import appsignal from 'app-lib/appsignal';
import { useMutation, useQueryClient } from 'react-query';
import ApiError from 'app-lib/apiV2/ApiError';
import { config } from 'app-config';
import { useClient } from 'app-lib/urql';
import { REDIRECT_PATH_STORAGE_KEY } from 'app-utils/constants/storageKeys';
import { useRouter } from 'next/router';
import IdentityApi from '../api/identityApi';
import {
  getUserEmail,
  getUserRoles,
  updateBundle,
} from '../models/rest/bundle';

export const BASE_KEY = 'IdentityApi';

export const MUTATION_KEYS = {
  login: [BASE_KEY, 'login'],
  logout: [BASE_KEY, 'logout'],
  forgotPassword: [BASE_KEY, 'forgotPassword'],
  resetPassword: [BASE_KEY, 'resetPassword'],
  startUserActivation: [BASE_KEY, 'startUserActivation'],
  finishUserActivation: [BASE_KEY, 'finishUserActivation'],
  refreshToken: [BASE_KEY, 'refreshToken'],
};

/**
 * Performs a login via the Identity API, sets the bundle to the local storage
 * and redirect to the original opened MP page if possible.
 *
 * @param {string | null} options.redirectTo
 */
export const useLoginMutation = (options = { redirectTo: '/dashboard' }) => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const { resetClient } = useClient();

  return useMutation({
    mutationKey: MUTATION_KEYS.login,
    mutationFn: async ({ email, password }) => {
      /**
       * Reset `urql` instance + cache.
       * Has to be done here; doesn't work in `loginFlow` as it happens too late.
       */
      await resetClient();

      return IdentityApi.login({ email, password })
        .then((response) => {
          const bundle = response?.data;

          // Authentication failure
          if (response?.data.code === 200) {
            throw new ApiError(`Login failed. Authentication error.`, response);
          }

          // Masquerade failed, i.e. user was not found
          if (response?.data.code === 400) {
            throw new ApiError(`Login failed. Masquerade failed.`, response);
          }

          // If the user is not a broker, fail the login.
          if (!getUserRoles(bundle).includes('broker')) {
            throw new ApiError(
              `Login failed. ${getUserEmail(
                bundle
              )} does not have the role "broker".`,
              response
            );
          }

          return bundle;
        })
        .catch((error) => {
          /*
           * Hides sensitive data like password and email from being sent to AppSignal.
           * Note: `structuredClone` does not work here, so the "old" way needs to fit.
           */
          let errorResponseCensored = error.response;
          if (errorResponseCensored?.config?.data) {
            errorResponseCensored = {
              ...error.response,
              config: {
                ...error.response.config,
                data: errorResponseCensored.config.data
                  .replace(/email":"[^"]*/, 'email":"secret')
                  .replace(/password":"[^"]*/, 'password":"secret'),
              },
            };
          }

          /*
           * Send error to Appsignal as long as it is not one of the common user errors like
           * - 401: wrong password or wrong email
           * - 401: account inactive
           * - 429: too many bad attempts in a short period of time
           * - etc.
           * See https://github.com/hausgold/identity-api/blob/c54b5539b4e26abfdc08b8b8a607fd2bedb58eae/app/api/jwt_api/api_v1/errors/authentication_failed_error.rb#L24.
           */
          if (
            !(error.response?.status === 401 || error.response?.status === 429)
          ) {
            const span = appsignal.createSpan();
            span.setAction('Login failed for an unknown reason.');
            span.setTags({
              response: errorResponseCensored,
              request: error.request,
            });
            appsignal.send(span);
          }

          return { response: error.response, request: error.request };
        });
    },
    onMutate: async () => {
      // Stop current queries
      await queryClient.cancelQueries(MUTATION_KEYS.login);
    },
    onSuccess: async (data) => {
      /*
       * As we do not raise multiple errors anymore, which are propagated to the browser as well,
       * this method is also called when running into the `.catch()` block of `mutationFn()`.
       * This check ensures we really have a bundle here which can be used. In case of an error,
       * we handle it in `.catch()` block already, so we just need to ensure we trigger no side effects at this point.
       */
      if (!data?.access_token) {
        return;
      }

      let oldRoute;
      const routeFromStorage = localStorage.getItem(REDIRECT_PATH_STORAGE_KEY);

      /*
       * Set saved redirect path.
       * Only pages which require a login are allowed to be redirected to.
       * The root page ('/dashboard') is the default redirect, so we do not check it here.
       */
      if (
        routeFromStorage &&
        (routeFromStorage.startsWith('/lead') ||
          routeFromStorage.startsWith('/search') ||
          routeFromStorage.startsWith('/?') ||
          routeFromStorage.startsWith('/team') ||
          routeFromStorage.startsWith('/help') ||
          routeFromStorage.startsWith('/settings') ||
          routeFromStorage.startsWith('/support'))
      ) {
        oldRoute = routeFromStorage;
      }

      // Remove redirect path to not redirect there again.
      localStorage.removeItem(REDIRECT_PATH_STORAGE_KEY);

      updateBundle(data);
      await router.replace(oldRoute || options?.redirectTo);
    },
  });
};

/**
 * Performs the "forgot password" recovery workflow via the Identity API
 */
export const useForgotPasswordMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEYS.forgotPassword,
    mutationFn: async ({ email }) =>
      IdentityApi.forgotPassword({
        email,
        metadata: {
          endpoint: `${config.url}/reset-password/confirm{?email,token}`,
        },
      })
        .then((response) => response?.data)
        .catch((error) => {
          throw new ApiError('Reset password failed', error.response);
        }),
    onMutate: async () => {
      // Stop current queries
      await queryClient.cancelQueries(MUTATION_KEYS.forgotPassword);
    },
  });
};

/**
 * Performs the "reset password" recovery workflow via the Identity API
 */
export const useResetPasswordMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEYS.resetPassword,
    mutationFn: async ({ email, token, password }) =>
      IdentityApi.resetPassword({
        email,
        token,
        password,
      }).then((response) => response?.data),
    onMutate: async () => {
      // Stop current queries
      await queryClient.cancelQueries(MUTATION_KEYS.resetPassword);
    },
    /*
     * OnSuccess: async (data) => {
     *   // Set returned user in query cache
     *   await queryClient.setQueryData(
     *     verkaeuferportalApiQueryKeys.user(data?.id),
     *     data
     *   );
     * },
     */
  });
};

/**
 * Performs the "start account activation" workflow; e.g. user enters the e-mail
 * for an inactive account and gets a confirmation mail.
 */
export const useStartUserActivation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEYS.startUserActivation,
    mutationFn: async ({ email }) =>
      IdentityApi.startUserActivation({
        email,
        metadata: {
          endpoint: `${config.url}/activate-account{?email,token}`,
        },
      }).then((response) => response?.data),
    onMutate: async () => {
      // Stop current queries
      await queryClient.cancelQueries(MUTATION_KEYS.startUserActivation);
    },
    /*
     * OnSuccess: async (data) => {
     *   // Set returned user in query cache
     *   await queryClient.setQueryData(
     *     verkaeuferportalApiQueryKeys.user(data?.id),
     *     data
     *   );
     * },
     */
  });
};

/**
 * Performs the "finish account activation" workflow; e.g. user clicks on the
 * link from the e-mail with the token and e-mail params.
 *
 * If successful, the bundle is returned directly and the user is logged in.
 */
export const useFinishUserActivation = (
  options = { redirectTo: '/dashboard' }
) => {
  const queryClient = useQueryClient();
  const router = useRouter();

  return useMutation({
    mutationKey: MUTATION_KEYS.finishUserActivation,
    mutationFn: async ({ email, token, password }) =>
      IdentityApi.finishUserActivation({
        email,
        token,
        password,
      }).then((response) => response?.data),
    onMutate: async () => {
      // Stop current queries
      await queryClient.cancelQueries(MUTATION_KEYS.finishUserActivation);
    },
    onSuccess: async (newBundle) => {
      updateBundle(newBundle);
      await router.push(options?.redirectTo);
    },
  });
};
